.o-donationForm // <form>
{
	position: relative;
	width: 100%;
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
	&__content
	{
		font-size: rem(18);
	}

	.form-page
	{
		padding: 5.5vw 6.25vw;
		@include breakpoint($medium)
		{
			// padding: 45px 55px;
			padding: 45px 40px;
		}
	}
	&[data-frequency="single"]
	{
		.monthly-only
		{
			display: none;
		}
	}
	&[data-frequency="monthly"]
	{
		.monthly-only
		{
			display: initial;
		}
	}
	@import '../base/defaults';
	@import '../base/headings';
	@import '../base/forms';
	@import 'icons';
	#form-page--3 .jsPrevPage
	{
		float: left;
	}
	#stripe-captcha,
	#plaid-captcha
	{
		opacity: 0;
		height: 0;
	}


	@import 'donation-general';
	@import 'field-grouping';
	@import 'tabs';
	@import "heading-content";
	@import 'buttons';
	@import 'button-group';
	@import "amount";
	@import 'progress';
	@import 'payment-methods';
	@import 'donation-summary';
	@import 'donation-note';
	@import 'email-suggest';
	@import 'processing-overlay';
	@import 'magnific-popup';

	@import '../pages/page-2';
	@import '../pages/page-3';
	// @import 'pages/thank-you';
}