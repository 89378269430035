

h1,h2,h3,h4,h5,h6
{
  margin: 0;
  color: $c_grey_6;
}

h1
{
  font-weight: 500;
  font-size: rem(70);
  line-height: 1.05263157894737; // 80px
  letter-spacing: 0;
}

h2
{
  font-weight: 500;
  font-size: rem(26);
  line-height: 1.11111111111111; // 40px
  letter-spacing: 0.5px;
}

h3
{
  font-weight: 500;
  font-size: rem(20);
  line-height: 1.25; // rem(30);
  letter-spacing: 0.35px;
}

h4
{
  font-weight: 400;
  font-size: rem(18);
  line-height: 1.47826086956522; // rem(34);
  letter-spacing: 1px;
}

h5
{
  font-weight: 500;
  font-size: rem(18);
  line-height: 1.2; // rem(24);
  letter-spacing: 0.5px;
}

h6
{
  font-weight: 500;
  font-size: rem(16);
  line-height: 1.1875; // rem(19);
  letter-spacing: 3px;
  text-transform: uppercase;
}

@include breakpoint($medium)
{
  h1
  {
    font-size: rem(76);
  }

  h2
  {
    font-size: rem(32);
  }

  h3
  {
    font-size: rem(24);
  }

  h4
  {
    font-size: rem(23);
  }

  h5
  {
    font-size: rem(20);
  }

  h6
  {
    font-size: rem(16);
  }

}