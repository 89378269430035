.radio-button-tabs
{
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	justify-content: flex-start;
	font-size: 0.8125em;
	color: #ADB1BC;
	text-transform: uppercase;
	text-align: center;
	border-bottom: 1px solid $c_grey_1;
	margin: 5px 0 25px;
	padding-bottom: 0;
	input[type=radio]
	{
		display: none;
		// Active Tab
		&:checked
		{
			+ label
			{
				color: $c_blue_2;
				border-bottom-color: $c_blue_1;
				.icon
				{
					fill: $c_orange_1;
				}
			}
		}
		+ label
		{
			font-weight: 500;
		}
	}
	label[for="tab--single"]
	{

	}
	// label[for="tab--monthly"] span
	// {
	//   position: relative;
	// }
	input[type=radio] + label,
	.tab--static
	{
		flex: 1 1 50%;
		line-height: 18px;
		margin-bottom: -1px;
		padding-bottom: 5px;
		border-bottom: 3px solid transparent;
		transition: .3s ease all;
		text-transform: uppercase;
		font-size: 0.75rem;
		color: #ADB1BC;
		letter-spacing: 0.09375rem;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		gap: 1ch;
		.icon
		{
			width: 16px;
			height: 18px;
			color: inherit;
			fill: #d7d7d8;
			vertical-align: top;
			margin-top: -0.1em;
			// margin-left: 0.5em;
			transition: .3s ease fill;
		}
	}
	label
	{
		cursor: pointer;
		transition: .3s ease all;
		&:hover,
		&:focus
		{
			border-bottom-color: rgba($c_blue_1, 0.2);
		}
	}
	.tab--static
	{
		font-weight: 500;
		color: inherit;
		font-size: 0.875rem;
	}

	// Text that isn't strictly required to understand the tab's purpose. We hide these at smaller sizes to prevent multi-line wrapping.
	.extra-text
	{
		display: none;
	}
	@include breakpoint(400px)
	{
		.extra-text
		{
			display: inline;
		}
	}
	@include breakpoint($medium)
	{
		// margin: 0 0 50px;
		margin: 0 0 30px;
		font-size: 0.875em;
		// label[for="tab--monthly"]
		// {
		//   padding-right: 20px;
		//   position: relative;
		//   .icon
		//   {
		//     position: absolute;
		//     right: -22px;
		//     top: -3px;
		//   }
		// }
		input[type=radio] + label,
		.tab--static
		{
			font-size: 0.875rem;
			padding-bottom: 10px;
		}
		.tab--static
		{
			font-size: 0.875rem;
			.icon
			{
				width: 21px;
				height: 24px;
			}
		}
	}
}