*
{
  box-sizing: border-box;
}

html
{
  font-family: "Reader", Helvetica, Arial, sans-serif;
  font-size: rem(16);
  line-height: rem(32);
  letter-spacing: 0.2px;
  color: $c_grey_5;

}

h1, h2, h3, h4, h5, h6, p, li, ul, input, label, textarea, a, ol, div, span, button
{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
}

a
{
  color: $c_blue_1;
}

figure
{
  margin: 0;
}

code
{
  display: inline-block;
  font-family: monospace;
  font-size: 1.1em;
  border-radius: 3px;
  padding: 1px 5px;
  outline: 1px solid rgba(0,0,0,0.2);
  background-color: rgba(0,0,0,0.1);
  line-height: 1;
  margin-left: 1px;
  margin-right: 1px;
}