.form-page--2
{
	.fieldGrid
	{
		margin-top: 1em;
		display: grid;
		gap: 1.5em 10px;
		> *
		{
			grid-column: 1 / -1;
		}
		> fieldset
		{
			margin: 0;
			padding: 0;
		}
		@media (min-width: 400px)
		{
			grid-template-columns: repeat(6, 1fr);

			.field--title { grid-column: 1 / 3; }
			.field--first_name { grid-column: 3 / 7; }
			.field--last_name_prefix { grid-column: 1 / 3; }
			.field--last_name { grid-column: 3 / 7; }
			.field--houseNumber { grid-column: 1 / 4; }
			.field--addition { grid-column: 4 / 7; }
		}
		@media (min-width: 480px)
		{
			.field--zip { grid-column: 1 / 3; }
			.field--houseNumber { grid-column: 3 / 5; }
			.field--addition { grid-column: 5 / 7; }
			.field--address { grid-column: 1 / 4; }
			.field--city { grid-column: 4 / 7; }
		}
	}
	// .field--receipt { grid-area: receipt; }
	.field--phone {
		display: grid;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr min-content;
		input { grid-row: 2 / -1; grid-column: 1 / 2; }
		label { grid-row: 1 / 2; grid-column: 1 / 2; }
		.fieldInfo // button
		{
			grid-column: 2 / 3;
			grid-row: 1 / -1;
			appearance: none;
			background-color: transparent;
			border: none;
			padding: 10px;
			opacity: 0.5;
			cursor: pointer;
			transition: 0.2s ease opacity;
			&:hover,
			&:focus
			{
				opacity: 1;
			}
			.icon
			{
				display: block;
				width: 24px;
				height: 24px;
			}
		}
	}
	.form__two-buttons
	{
		display: flex;
		gap: 0.5em;
		flex-flow: row nowrap;
		justify-content: space-between;
		margin-top: 2rem;
		.button
		{
			// padding: 14px 5px;
			padding-left: 5px;
			padding-right: 5px;
			flex: 1 1 50%;
			min-width: unset;
		}
	}
}