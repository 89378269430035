label
{
	display: block;
	font-weight: 500;
	font-size: rem(13);
	letter-spacing: 1.8px;
	text-transform: uppercase;
	color: $c_grey_2;
	line-height: 1em;
}
fieldset.error
{
	label
	{
		color: $c_red_1;
	}
}
.-rel { position: relative; }
// #card-errors
// {
// 	line-height: 1em;
// }
// label.error,
// #card-errors.error
// {
// 	position: absolute;
// 	bottom: -1.115rem;
// 	text-transform: none;
// 	letter-spacing: 0.5px;
// 	color: $c_red_1;
// 	padding: 0;
// 	font-size: 13px;
// 	font-weight: 500;
// }
// #card-errors.error
// {
// 	display: none;
// }
// #card-element.StripeElement--invalid
// {
// 	border-color: $c_red_1;
// 	~ #card-errors.error
// 	{
// 		display: block;
// 	}
// 	~ label
// 	{
// 		color: $c_red_1;
// 	}
// }

input[type="checkbox"],
input[type="radio"]
{
	// appearance: none;
	width: 1rem;
	height: 1rem;
	border: 1px solid $c_grey_3;
	background: #fff;
	&.error
	{
		+ label
		{
			color: $c_red_1;
		}
	}
	+ label
	{
		// display: inline-block;
		font-weight: normal;
		cursor: pointer;
		text-transform: none;
		font-size: inherit;
		color: $c_grey_5;
		letter-spacing: 0.75px;
	}
}

input[type="checkbox"]
{
	border-radius: 2px;
}

input[type="radio"]
{
	border-radius: 1rem;
}

// #card-element,
#{$textual-inputs} {
	appearance: none;
	border: none;
	border-bottom: 1px solid $c_grey_1;
	padding: 4px 0;
	display: block;
	width: 100%;
	// background: white;
	background: transparent;
	height: 1.5em;
	font-size: 1rem;
	line-height: 22px;
	border-radius: 0;
	transition: .3s ease all;
	&::placeholder
	{
		// color: $c_grey_1;
		color: rgba(black, 0.2);
		text-align: center;
	}
	&:focus
	{
		outline: none;
		border-color: $c_blue_1;
		+ label
		{
			color: $c_blue_1;
		}
	}
	&.error
	{
		// box-shadow: 0 0 7px inset $c_red_1;
		border-color: $c_red_1;
		color: $c_red_1;
		background-color: rgba($c_red_1, 0.2);
		// background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 32 32' fill='%23f44c4c'><path d='M31.414 28.586l-28-28-2.828 2.828 28 28z'></path><path d='M3.414 31.414l28-28-2.828-2.828-28 28z'></path></svg>");
		// background-position: right 0 top 20%;
		// background-repeat: no-repeat;
		// background-size: 0.875rem;
		~ label
		{
			color: $c_red_1;
		}
	}
	&.has-possible-typo
	{
		border-color: $c_orange_1;
		color: $c_orange_1;
		~ label
		{
			color: $c_orange_1;
		}
	}
}
:-ms-input-placeholder
{
	color: $c_grey_1 !important;
}

// #card-element
// {
// 	height: auto;
// }

[type="submit"],
[type="reset"]
{
	appearance: none;
	border: none;
	border-radius: 3px;
	padding: 6px 15px;
	background-color: $c_blue_1;
	color: #fff;
	cursor: pointer;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.15em;
	transition: 0.2s ease all;
	&:hover,
	&:focus
	{
		background-color: lighten($c_blue_1, 4%);
	}
}

select
{
	border: none;
	appearance: none;
	border-bottom: 1px solid $c_grey_1;
	border-radius: 0;
	padding: 4px 24px 3px 0;
	display: block;
	width: 100%;
	// background: white;
	background: transparent;
	// height: 33px;
	font-size: 16px;
	line-height: 1;
	background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 50 32' width='16' height='10' xmlns='http://www.w3.org/2000/svg'><path d='M24.889 23.164l18.705-20.538 5.258 4.788-21.333 23.425h-5.258l-21.333-23.425 5.258-4.788z' fill='%23d4d3d8'/></svg>");
	background-position: right 0 top 50%;
	background-repeat: no-repeat;
	&::-ms-expand
	{
		display: none;
	}
	&:focus
	{
		outline: none;
		border-color: $c_blue_1;
		background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 50 32' width='16' height='10' xmlns='http://www.w3.org/2000/svg'><path d='M24.889 23.164l18.705-20.538 5.258 4.788-21.333 23.425h-5.258l-21.333-23.425 5.258-4.788z' fill='%230079c1'/></svg>");
		+ label
		{
			color: $c_blue_1;
		}
	}

	&.error
	{
		// box-shadow: 0 0 7px inset $c_red_1;
		border-color: $c_red_1;
		color: $c_red_1;
		background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 50 32' width='16' height='10' xmlns='http://www.w3.org/2000/svg'><path d='M24.889 23.164l18.705-20.538 5.258 4.788-21.333 23.425h-5.258l-21.333-23.425 5.258-4.788z' fill='%23f44c4c'/></svg>");
		~ label
		{
			color: $c_red_1;
		}
	}
}

input[readonly],
select[readonly],
textarea[readonly]
{
	background-color: #ddd;
	color: #777;
	&.jsClickToEdit
	{
		cursor: pointer;
	}
}

// .button-group
// {
// 	margin-bottom: 0.75em;
// }

// .col--postal-code
// {
//   padding-left: 10px;
// }

.subscription-items
{
	margin-bottom: 1.5rem;
	padding-left: 0.5rem;
	.subscription-item
	{
		list-style-type: none;
		margin-bottom: 0.5rem;
		input[type=radio]
		{
			position: relative;
			top: -2px;
			margin-right: 0.5rem;
		}
	}
}

input[name="iban"]
{
	font-family: monospace;
}