.email-suggestion
{
	z-index: 9999;
	// display: flex;
	// flex-flow: row nowrap;
	// align-items: flex-start;
	// justify-content: flex-start;
	position: absolute;
	visibility: visible;
	opacity: 1;
	transition-delay: 0s;
	bottom: 2.5em;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 100%;
	background-color: white;
	padding: 15px 20px 15px 15px;
	box-shadow:
		0 10px 20px rgba(black, 0.19),
		0 6px 6px rgba(black, 0.23),
		0 -5px 30px white,
		0 -5px 20px white,
		0 -5px 10px white;
	border-radius: 5px;
	border: 1px solid #bbb;
	font-size: 14px;
	&[hidden]
	{
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.2s ease, visibility 0s ease 0.2s;
	}
	&::after
	{
		content: "";
		position: absolute;
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		border-top: 10px solid white;
		border-right: 10px solid transparent;
		border-left: 10px solid transparent;
		filter: drop-shadow(0 1px 0 #bbb);
	}
	button
	{
		appearance: none;
		border: none;
		background-color: transparent;
	}
	&__email // button
	{
		padding: 0;
		text-decoration: underline;
		color: $c_blue_1;
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
		cursor: pointer;
	}
	p
	{
		margin: 0;
	}
	.icon
	{
		display: inline-block;
		width: 1em;
		height: 1em;
		stroke-width: 0;
		stroke: currentColor;
		fill: currentColor;
		&-warning
		{
			fill: $c_orange_2;
			vertical-align: -1px;
		}
	}
	&__close
	{
		position: absolute;
		top: 0;
		right: 0;
		padding: 7px;
		line-height: 16px;
		cursor: pointer;
		&:hover
		{
			.icon
			{
				fill: $c_grey_6;
			}
		}
		.icon
		{
			fill: $c_grey_1;
			transition: 0.2s ease fill;
		}
	}
	@media screen and (min-width: 737px)
	{
		max-width: 96%;
		// left: 10%;
		// transform: none;
	}
}