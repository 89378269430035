// Fieldsets should only have a label (or legend), a field or equivalent wrapper, and extra info elements such as description, errors, etc:
// 	<fieldset>
// 		<label/>
// 		<input/> or <div class="field" />
// 		<p class="description"/>
// 		<ul class="errors"/>
// 	</fieldset>
fieldset
{
	margin: 1.75em 0;
	display: grid;
	gap: 0.25em 0.5em;
	grid-template-columns: auto 1fr;
	align-items: center;
	&[hidden]
	{
		display: none !important;
	}
	> *
	{
		grid-column: 1 / -1;
		&[type="checkbox"],
		&[type="radio"]
		{
			grid-column: 1 / 2;
			+ label
			{
				grid-column: 2 / 3;
			}
		}
	}
	// &.fieldset
	// {
	// 	border: 1px dotted #aaa;
	// 	padding: 10px;
	// 	legend
	// 	{
	// 		padding: 0 10px;
	// 	}
	// }
	legend
	{
		font-weight: 500;
	}
	.info
	{
		font-size: 14px;
		opacity: 0.6;
		line-height: 1.4;
		p+p { margin-top: 0.33em; }
		>*:first-child
		{
			margin-top: 0;
		}
		>*:last-child
		{
			margin-bottom: 0;
		}
		// summary
		// {
		// 	cursor: pointer;
		// }
		a
		{
			// text-decoration: underline;
			border-bottom: 1px solid currentColor;
		}
	}
	// details
	// {
	// 	border-radius: 3px;
	// 	border: 1px solid transparent;
	// 	padding: 0.5em 1em;
	// 	transition: 0.2s ease all;
	// 	&[open]
	// 	{
	// 		border-color: rgba(black, 0.2);
	// 		background-color: rgba(black, 0.1);
	// 	}
	// }
}

// .field
// {
// 	margin: 1.5em 0 0;
// 	display: flex;
// 	flex-flow: column-reverse nowrap;
// 	justify-content: flex-end;
// 	&--row
// 	{
// 		margin: 0 -10px;
// 	}
// 	&--col
// 	{
// 		margin: 1.5em 10px 0;
// 		display: flex;
// 		flex-flow: column-reverse nowrap;
// 		justify-content: flex-end;
// 	}
// }

.field
{
	position: relative;
	// margin: 1em 0 0;

	// &.checkbox-radio-inputs
	// {
	// 	display: flex;
	// 	flex-flow: row wrap;
	// 	align-items: center;
	// 	line-height: 1.3;
	// 	[type=checkbox],
	// 	[type=radio]
	// 	{
	// 		flex: 0 1 10%;
	// 		margin-right: 0.5em;
	// 	}
	// 	label
	// 	{
	// 		flex: 0 1 90%;
	// 		font-size: 1rem;
	// 	}
	// }

	// @supports (display: grid)
	// {
	// 	&.checkbox-radio-inputs
	// 	{
	// 		display: grid;
	// 		grid-template-columns: auto 1fr;
	// 		[type=checkbox],
	// 		[type=radio]
	// 		{
	// 			grid-column: 1 / 2;
	// 			align-self: start;
	// 		}
	// 		label
	// 		{
	// 			grid-column: 2 / 3;
	// 		}
	// 	}
	// }
}

@include breakpoint($medium)
{
	.field
	{
		// margin: 1.75em 0 0;
		&--row
		{
			display: flex;
		}
		&--col
		{
			// flex: 1 1 0;
			margin: 1.75em 10px 0;
		}
		&--1
		{
			width: 100%;
		}
		&--1-2
		{
			width: 50%;
		}
		&--1-3
		{
			width: 33.3%;
		}
		&--2-3
		{
			width: 66.6%;
		}
		// &.checkbox-radio-inputs
		// {
		// 	margin-top: 1.25em;
		// }
	}
}


/* .combined-fields
{
	display: flex;
	.combined-field1
	{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.combined-field2
	{
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}
} */

/* .input-group
{
	display: flex;
	flex-flow: row nowrap;
	align-items: stretch;
	min-height: $field-height;
	.input-group__label
	{
		flex: 0 1 auto;
		display: flex;
		align-items: center;
		padding: 0 1em;
		line-height: 1;
		font-size: rem(24);
		font-weight: 500;
		color: $c_grey_2;
		border: 1px solid $c_grey_3;
		border-right: none;
		border-radius: 5px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.input-group__field
	{
		flex: 0 1 auto;
		border-radius: 5px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		height: 100%;
		padding: 0 1em;
		font-size: rem(24);
		font-weight: 500;
		color: $c_grey_2;
	}
}

@supports(display: grid)
{
	.input-group
	{
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: $field-height auto;
		grid-template-areas:
		"label field"
		". error";
		.input-group__label
		{
			grid-area: label;
		}
		.input-group__field
		{
			grid-area: field;
		}
		label.error
		{
			grid-area: error;
		}
	}
} */