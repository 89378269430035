.processingOverlay
{
	--blur-amount: 5px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(white,0.85);
	backdrop-filter: blur(7px);
	z-index: 5;
	display: grid;
	place-items: center;
	transition: 0.2s ease opacity, 0s ease visibility;
	text-align: center;
	padding: 1em;
	&.has-spinner
	{
		cursor: progress;
		.icon-spinner
		{
			display: inline-block;
		}
		.processingOverlay__close
		{
			display: none;
		}
	}
	&[hidden]
	{
		visibility: hidden;
		opacity: 0;
		transition-delay: 0s, 0.2s;
	}
	&__close
	{
		appearance: none;
		display: flex;
		padding: 1em;
		margin: 0;
		background-color: transparent;
		border: none;
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 0;
	}
	&__title
	{
		text-transform: capitalize;
		color: $c_blue_1;
	}
	&__message
	{
		margin-top: 0.5em;
	}
	dl
	{
		margin: 1em 0 0;
		display: grid;
		grid-template-columns: min-content 1fr;
		gap: 0.25em 1em;
		text-align: left;
		dt
		{
			grid-column: 1 / 2;
			font-weight: bold;
		}
		dd
		{
			grid-column: 2 / 3;
			font-family: monospace;
		}
	}
	.icon-spinner
	{
		display: none;
		fill: none;
		stroke: currentColor;
		stroke-width: 2px;
		font-size: 2em;
		.outer
		{
			transform-origin: 50%;
			animation: rotate 6s linear 0s infinite normal forwards;
		}
		.inner
		{
			transform-origin: 50%;
			animation: rotate 1.367s linear 0s infinite normal forwards;
		}
	}
}

@keyframes rotate {
	from {
		transform: rotateZ(0deg)
	}
	to {
		transform: rotateZ(360deg)
	}
}