// ########    ###    ########  ########
// ##         ## ##   ##     ## ##
// ##        ##   ##  ##     ## ##
// ######   ##     ## ##     ## ######
// ##       ######### ##     ## ##
// ##       ##     ## ##     ## ##
// ##       ##     ## ########  ########

/* overlay at start */
.mfp-fade
{
  will-change: opacity;
  &.mfp-bg
  {
    opacity: 0;
    transition: all 0.15s ease-out;
    /* overlay animate in */
    &.mfp-ready
    {
      opacity: 0.8;
    }
    /* overlay animate out */
    &.mfp-removing
    {
      opacity: 0;
    }
  }

  /* content at start */
  &.mfp-wrap
  {
    will-change: opacity, transform;
    .mfp-content
    {
      opacity: 0;
      transform: scaleX(0.93) scaleY(0.93);
      transition: opacity 0.15s ease-out, transform 0.15s ease-out;
    }
    /* content animate in */
    &.mfp-ready
    {
      .mfp-content
      {
        opacity: 1;
        transform: scaleX(1) scaleY(1);
      }
    }
    /* content animate out */
    &.mfp-removing
    {
      .mfp-content
      {
        opacity: 0;
        transition: opacity 0.15s ease-out, transform 0.15s ease-out;
        transform: scaleX(0.9) scaleY(0.9);
      }
    }
  }
}

// Indicate overlay clickable
.mfp-wrap
{
  cursor: pointer;
}

//  ######  ##        #######   ######  ########
// ##    ## ##       ##     ## ##    ## ##
// ##       ##       ##     ## ##       ##
// ##       ##       ##     ##  ######  ######
// ##       ##       ##     ##       ## ##
// ##    ## ##       ##     ## ##    ## ##
//  ######  ########  #######   ######  ########

button.mfp-close
{
  padding: 10px;
  width: unset;
  height: unset;
  line-height: 0;
  font-size: 20px;
  > *
  {
    // Solves this: https://css-tricks.com/slightly-careful-sub-elements-clickable-things/
    pointer-events: none;
  }
}

.mfp-container
{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.mfp-content
{
  flex: 0 1 30em;
  background-color: $c_white_1;
  padding: 2em;
  font-size: 16px;
  line-height: 1.5;
  main
  {
    > *:first-child { margin-top: 0; }
    > p:last-of-type { margin-bottom: 0; }
    h1 { font-size: 2em; }
    h2 { font-size: 1.8em; }
    h3 { font-size: 1.6em; }
    h4 { font-size: 1.4em; }
    h5 { font-size: 1.2em; }
    h6 { font-size: 1.1em; }
  }
}